@import url(./styles/fonts/NotoSansKR/noto-sans-kr.font.css);
@import url(./styles/fonts/Pretendard/pretendard.font.css);
@import url(./styles/fonts/SCoreDream/s-core-dream.font.css);
@import url(./styles/fonts/Hahmlet/hahmlet.font.css);
@import url(./styles/fonts/Suite/suite.font.css);

:root {
    /* 
        색상 코드 Opacity 기준 
        100 : 19
        200 : 32
        300 : 4b
        400 : 64
        500 : 7d
        600 : 96
        700 : af
        800 : c8
        900 : e1
        1000 : fa
    */
    --mainColor: #ee9ca7;
    --mainColor100: #fdf6f6;
    --mainColor200: #fcebee;
    --mainColor300: #fbe2e5;
    --mainColor400: #f9d9dd;
    --mainColor500: #f7ced4;
    --mainColor600: #f5c5cc;
    --mainColor700: #f5bcc2;
    --mainColor800: #f3b1ba;
    --mainColor900: #f0a8b1;
    --mainColor1000: #ee9ea9;
    --mainColorHover: #f0a5af;
    --defaultElementBC: #0000000d;

    /* Original Palette START */
    --redColorOriginal: #ff1744;
    --greenColorOriginal: #07bc0c;
    /* Original Palette END */

    /* Paradise Pink + Caribbean Green + NCS Blue Color Palette START */
    --redColor1: #ef476f;
    --greenColor1: #06d6a0;
    --yellowColor1: #ffd166;
    --blueColor1: #118ab2;
    --navyColor1: #073b4c;
    /* Paradise Pink + Caribbean Green + NCS Blue Color Palette END */

    --defaultBoxShadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    --defaultBoxShadow2: 4px 12px 30px 6px rgba(0, 0, 0, 0.09);
    --defaultBoxShadow2Hover: 4px 12px 20px 6px rgba(0, 0, 0, 0.18);
    --defaultBoxShadow3: 2px 6px 30px 0px rgba(0, 0, 0, 0.09);

    /* --mainFont: "Noto Sans KR"; */
    --mainFont: "Pretendard";
    /* --mainFont: "SUITE"; */
}
* {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* mainFont 실패 시 뒤에 선언된 폰트들로 렌더링 */
    font-family: var(--mainFont), -apple-system, BlinkMacSystemFont,
        "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo",
        sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
    width: 5px;
}

body::-webkit-scrollbar-thumb {
    background-color: #b0b0b0;
    border-radius: 10px;
}
body::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

@media all and (max-width: 768px) {
    body{
        overflow: auto;
    }
    /* body::-webkit-scrollbar {
        width: 0px;
    } */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    color: #000;
    text-decoration: none;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes marquee {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

@keyframes scroll-left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
